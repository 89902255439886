import { Image, Skeleton, useBoolean } from "@chakra-ui/react";
import { motion } from "framer-motion";

export const PhotoImage = ({ photo, isTouchScreen = false }) => {
  const [isLoaded, setIsLoaded] = useBoolean();

  return (
    <Skeleton
      isLoaded={isLoaded}
      h={
        !isLoaded
          ? `${Math.floor(Math.random() * (350 - 200 + 1) + 200)}px`
          : "auto"
      }
      w="full"
    >
      <Image
        data-grid-groupkey={1}
        key={photo.id}
        src={photo.src}
        w="full"
        as={isTouchScreen ? Image : motion.img}
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { duration: 0.4, delay: 0.1 },
        }}
        loading="lazy"
        onContextMenu={(e) => e.preventDefault()}
        onLoad={setIsLoaded.on}
      />
    </Skeleton>
  );
};
