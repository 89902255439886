import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { uniqBy } from "lodash";
import { PHOTO_GALLARY } from "Data/Images/Photos";
import { PHOTOS_PATH } from "endpoints";

const PhotoContext = createContext({
  list: [],
  startingPos: 0,
  hasMore: true,
  isLoading: true,
  loadMore: () => {},
});

const PhotoProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [photos, setPhotos] = useState({
    list: [],
    startingPos: 0,
    hasMore: true,
    isLoading: true,
    loadMore: () => {},
  });

  const fetchPhotos = async (from, to) => {
    setPhotos((previousData) => ({ ...previousData, isLoading: true }));

    if (from === 0)
      setTimeout(() => {
        setPhotos({
          list: PHOTO_GALLARY.slice(from, to),
          startingPos: to,
          hasMore: to <= PHOTO_GALLARY.length,
        });
        setPhotos((previousData) => ({ ...previousData, isLoading: false }));
      }, 1000);
    else
      setTimeout(() => {
        setPhotos((previousData) => ({
          list: uniqBy(
            [...previousData.list, ...PHOTO_GALLARY.slice(from, to || 10)],
            "id"
          ),
          startingPos: to,
          hasMore: to <= PHOTO_GALLARY.length,
        }));
        setPhotos((previousData) => ({ ...previousData, isLoading: false }));
      }, 1000);
  };

  const loadMore = () => {
    if (photos?.hasMore) {
      setPhotos((previousData) => ({ ...previousData, isLoading: true }));
      fetchPhotos(photos.startingPos, photos.startingPos + 10);
    }
  };

  useEffect(() => {
    if (photos.list.length === 0 && pathname === PHOTOS_PATH)
      fetchPhotos(0, 20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <PhotoContext.Provider value={{ ...photos, loadMore }}>
      {children}
    </PhotoContext.Provider>
  );
};

const usePhoto = () => useContext(PhotoContext);

export { PhotoProvider, usePhoto };
