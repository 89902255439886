import { useLocation } from "react-router-dom";
import { Tooltip } from "@chakra-ui/react";
import "./Footer.css";
import { PHOTOS_PATH } from "endpoints";

export const Footer = () => {
  const { pathname } = useLocation();

  return (
    <footer>
      <div>
        <a
          href="https://www.twitter.com/saurabhchirde"
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip label="Twitter" hasArrow placement="top">
            <i className="fab fa-twitter" />
          </Tooltip>
        </a>
        <a
          href="https://www.github.com/saurabhchirde"
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip label="Github" hasArrow placement="top">
            <i className="fab fa-github" />
          </Tooltip>
        </a>
        <a
          href="https://www.linkedin.com/in/saurabhchirde"
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip label="LinkenIn" hasArrow placement="top">
            <i className="fab fa-linkedin" />
          </Tooltip>
        </a>
        <a
          href="https://www.instagram.com/saurabhchirde"
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip label="Instagram" hasArrow placement="top">
            <i className="fab fa-instagram" />
          </Tooltip>
        </a>
        <a
          href="https://www.facebook.com/schirdephotography"
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip label="Facebook" hasArrow placement="top">
            <i className="fab fa-facebook" />
          </Tooltip>
        </a>
      </div>
    </footer>
  );
};
