import {
  Box,
  Flex,
  Image,
  Text,
  Divider,
  useBreakpointValue,
  useColorMode,
  SimpleGrid,
  VStack,
  IconButton,
  Link,
} from "@chakra-ui/react";
import { Cards, CardTwo } from "Components";
import {
  UIDark,
  cartDark,
  quizDark,
  playDark,
  noteDark,
  UILight,
  cartLight,
  quizLight,
  playLight,
  noteLight,
  colorsLight,
  colorsDark,
  qrLight,
} from "Data/Images/ScreenShots";
import "./Pages.css";
import { SKILLS } from "Data/skills";
import { OTHER_APPS_PATH } from "endpoints";
import { Tilt } from "react-tilt";
import {
  cartGif,
  noteGif,
  playGif,
  quizGif,
  uiGif,
} from "Data/Images/ProjectGifs";
import { FaChevronDown } from "react-icons/fa";

export const HomePage = () => {
  const { colorMode } = useColorMode();
  const isSmallScreen = useBreakpointValue({ base: true, md: true, lg: false });

  const proejctCards = [
    {
      id: "4192f8aa-7124-4e00-8690-c36e23beea61",
      wrapperClass: "with-live",
      title: "Pebble Cart",
      subtitle: "An E-commerce Store For Photography Enthusiast",
      imgSrc: colorMode === "dark" ? cartDark : cartLight,
      gif: cartGif,
      description:
        "Tech Stack: ReactJS, Context API, useReducer, PebbleUI component library and Mockbee for backend.",
      primaryLink: "https://cart.pebbleui.com",
      secondaryLink: "https://github.com/saurabhchirde/pebble-cart-ecom-react",
      skeletonWidth: ["20rem", "20rem", "20rem", "22rem"],
    },
    {
      id: "5855a092-428e-4a6e-9789-efe83516269a",
      wrapperClass: "with-live",
      title: "Pebble Quiz",
      subtitle: "A Quiz app to boost your memory.",
      imgSrc: colorMode === "dark" ? quizDark : quizLight,
      gif: quizGif,
      description:
        "Tech Stack: ReactJS, Context API, useReducer, PebbleUI component library and Firebase for backend.",
      primaryLink: "https://quiz.pebbleui.com",
      secondaryLink: "https://github.com/saurabhchirde/pebble-quiz",
      skeletonWidth: ["20rem", "20rem", "20rem", "22rem"],
    },
    {
      id: "11201507-afa2-4ea2-aa1c-9e1314fe90f0",
      wrapperClass: "with-live",
      title: "Pebble UI",
      subtitle: "Component Library",
      imgSrc: colorMode === "dark" ? UIDark : UILight,
      gif: uiGif,
      description: "Minimal UI Component Library, created using HTML and CSS.",
      primaryLink: "https://pebbleui.com",
      secondaryLink: "https://github.com/saurabhchirde/Pebble-UI",
      skeletonWidth: ["20rem", "20rem", "20rem", "22rem"],
    },
    {
      id: "74b203d3-eac1-4a39-b1ab-8d3820263c18",
      wrapperClass: "with-live",
      title: "Pebble Play",
      subtitle: "Video Library For Photographers and Film makers.",
      imgSrc: colorMode === "dark" ? playDark : playLight,
      gif: playGif,
      description:
        "Tech Stack: ReactJS, Context API, useReducer, PebbleUI component, library, ExpressJS and MongoDB.",
      primaryLink: "https://play.pebbleui.com",
      secondaryLink: "https://github.com/saurabhchirde/pebble-play-react",
      skeletonWidth: ["20rem", "20rem", "20rem", "22rem"],
    },
    {
      id: "34fbc6aa-9b7d-4ac8-b74b-38eb28b619dd",
      wrapperClass: "with-live",
      title: "Pebble Note",
      subtitle: "Simple and Easy note keeping app to increase productivity.",
      imgSrc: colorMode === "dark" ? noteDark : noteLight,
      gif: noteGif,
      description:
        "Tech Stack: ReactJS, Context API, useReducer, PebbleUI component library and Mockbee for backend.",
      primaryLink: "https://note.pebbleui.com",
      secondaryLink: "https://github.com/saurabhchirde/pebble-note-app",
      skeletonWidth: ["20rem", "20rem", "20rem", "22rem"],
    },
    {
      id: "49803a73-0e66-4115-ae75-f341e6b094d5",
      wrapperClass: "with-live",
      title: "Pebble Colors",
      subtitle: "A tool to Pick/Extract Colors From Photos",
      imgSrc: colorMode === "dark" ? colorsDark : colorsLight,
      gif: "",
      description: "Tech Stack: NextJS and Chakra UI.",
      primaryLink: "https://colors.pebbleui.com/",
      secondaryLink: "https://github.com/saurabhchirde/",
      skeletonWidth: ["20rem", "20rem", "20rem", "22rem"],
    },
    {
      id: "65685c86-0d78-42a9-a1c7-d5bee8f8fc3c",
      wrapperClass: "with-live",
      title: "Pebble QR",
      subtitle: "Create QR from any text",
      imgSrc: qrLight,
      gif: "",
      description: "Tech Stack: NextJS and Chakra UI.",
      primaryLink: "https://qr.pebbleui.com/",
      secondaryLink: "https://github.com/saurabhchirde/",
      skeletonWidth: ["20rem", "20rem", "20rem", "22rem"],
    },
  ];

  const defaultOptions = {
    reverse: true, // reverse the tilt direction
    max: 35, // max tilt rotation (degrees)
    perspective: 800, // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1, // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000, // Speed of the enter/exit transition
    transition: true, // Set a transition on enter/exit.
    axis: null, // What axis should be disabled. Can be X or Y.
    reset: false, // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
  };

  const renderHeader = (
    <>
      <Text
        as="h1"
        fontSize={["4xl", "5xl", "6xl", "7xl", "8xl", "9xl"]}
        fontWeight="bold"
        mt={[16, 24, 0]}
      >
        Saurabh Chirde
      </Text>
      <VStack mt={[0, -1, -2, -4]} mb={[0, 0, 0, 0, 28]}>
        <Divider borderColor="var(--subtitle-color)" w="50%" />
        <Text
          as="h2"
          fontSize={["md", "lg", "2xl"]}
          fontWeight="400"
          color="var(--subtitle-color)"
          letterSpacing={[4, 6, 6, 6, 10]}
        >
          MERN STACK DEVELOPER
        </Text>
      </VStack>
    </>
  );

  return (
    <Box id="home-container" className="home-page" pt={isSmallScreen ? 6 : 0}>
      <Flex className="home-page-header" direction="column" pos="relative">
        {isSmallScreen ? (
          renderHeader
        ) : (
          <Tilt options={defaultOptions}>{renderHeader}</Tilt>
        )}
        {!isSmallScreen && (
          <IconButton
            icon={<FaChevronDown />}
            as={Link}
            href="#skills-container"
            pos="absolute"
            bottom="3rem"
            isRound
            size="lg"
            aria-label="Scroll Down"
          />
        )}
      </Flex>
      <Flex
        id="skills-container"
        direction="column"
        alignItems="center"
        w={["90%", "90%", "90%", "80%"]}
        mx="auto"
        pt={["2rem", "2rem", "2rem", "4rem"]}
      >
        <Text as="h2" className="section-title" fontSize={["lg", "lg", "2xl"]}>
          Skills & Tools
        </Text>
        <hr />
        <Flex
          wrap="wrap"
          gap={4}
          mt={6}
          alignItems="center"
          justifyContent="center"
        >
          {SKILLS.map((skill) => (
            <Image
              pos="relative"
              zIndex={2}
              w={["40px", "40px", "40px", "60px"]}
              h={["40px", "40px", "40px", "60px"]}
              key={skill.src}
              src={skill.src}
              onContextMenu={(e) => e.preventDefault()}
            />
          ))}
        </Flex>
      </Flex>
      <Flex
        w={["90%", "90%", "95%", "95%", "90%", "80%"]}
        wrap="wrap"
        justifyContent="center"
        alignItems="center"
        className="project-section main-projects"
        gap={["0.5rem", "0.5rem", "1rem", "2rem"]}
        m={["6rem auto", "6rem auto", "8rem auto", "8rem auto"]}
        direction="column"
      >
        <Flex className="section-header" direction="column" alignItems="center">
          <Text
            as="h2"
            className="section-title"
            fontSize={["lg", "lg", "2xl"]}
          >
            Projects
          </Text>
          <hr />
        </Flex>
        <SimpleGrid
          columns={[1, 1, 1, 2]}
          spacing={isSmallScreen ? "10px" : "20px"}
          w="full"
        >
          {proejctCards?.map((card) => (
            <Cards key={card.id} {...card} />
          ))}
        </SimpleGrid>
        <CardTwo
          wrapperClass="without-live p-16"
          title="Other Explorations"
          description="Other small randomly created apps using JavaScript, HTML, and CSS"
          primaryLabel="View more"
          primaryLink={OTHER_APPS_PATH}
        />
      </Flex>
    </Box>
  );
};
