import { Button, Box, Flex, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import "./Cards.css";

export const CardTwo = ({
  wrapperClass,
  title,
  description,
  primaryLabel,
  primaryLink,
}) => {
  return (
    <Box as="section" className={wrapperClass}>
      <Flex
        direction={["column", "column", "row"]}
        alignItems={["flex-start", "flex-start", "center"]}
        justifyContent="space-between"
      >
        <VStack alignItems="flex-start">
          <Text as="h4" fontSize="18px">
            <strong>{title}</strong>
          </Text>
          <Text as="p">{description}</Text>
        </VStack>
        <Link to={primaryLink}>
          <Button
            bgColor="var(--primary-color)"
            color="white"
            borderRadius="2xl"
            fontWeight="normal"
            variant="primary"
          >
            {primaryLabel}
          </Button>
        </Link>
      </Flex>
    </Box>
  );
};
