import {
  Flex,
  Image,
  Text,
  useBoolean,
  Skeleton,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";
import { PrimaryButton } from "Components/UI/Buttons/PrimaryButton";
import { SecondaryButton } from "Components/UI/Buttons/SecondaryButton";
import "./Cards.css";
import { useEffect } from "react";
import { FaEye, FaCode } from "react-icons/fa";
import { motion } from "framer-motion";
import { ProjectPreview } from "./ProjectPreview";
import { useLocation, useNavigate } from "react-router-dom";

export const Cards = ({
  wrapperClass,
  imgSrc,
  title,
  subtitle,
  description,
  primaryLink,
  secondaryLink,
  skeletonWidth,
  direction,
  onOtherApps = false,
  gif,
  id,
}) => {
  const [imageLoaded, setImageLoaded] = useBoolean();
  const [showPreview, setShowPreview] = useBoolean();
  const navigate = useNavigate();
  const location = useLocation();

  const isSmallScreen = useBreakpointValue({ base: true, md: true, lg: false });

  useEffect(() => {
    const timer = setTimeout(() => setImageLoaded.on(), 1000);
    return () => clearTimeout(timer);
  }, [setImageLoaded]);

  const rnderCta = (primaryProps = {}, secondaryProps = {}) => (
    <Flex align="center" justify="center" gap={2} mt={4} w="full">
      <PrimaryButton
        as={Link}
        href={primaryLink}
        size={isSmallScreen || onOtherApps ? "sm" : "md"}
        leftIcon={<FaEye />}
        label="Live"
        {...primaryProps}
      />
      <SecondaryButton
        as={Link}
        href={secondaryLink}
        size={isSmallScreen || onOtherApps ? "sm" : "md"}
        leftIcon={<FaCode />}
        label="Code"
        {...secondaryProps}
      />
    </Flex>
  );

  const onCardClick = () => {
    setShowPreview.on();
    navigate(`${location.pathname}?project=${id}`, { replace: true });
  };

  const onClose = () => {
    setShowPreview.off();
    navigate(location.pathname, { replace: true });
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const projectId = query.get("project");

    if (projectId === id && !showPreview) {
      setShowPreview.on();
    }
  }, [id, location.search, setShowPreview, showPreview]);

  return (
    <>
      <Flex
        className={wrapperClass}
        pos="relative"
        direction="column"
        as={motion.div}
        whileHover={{ scale: 1.02 }}
        py={onOtherApps ? 2 : 4}
        onClick={onCardClick}
        cursor="pointer"
      >
        {!imageLoaded ? (
          <Skeleton
            width={skeletonWidth}
            h={isSmallScreen ? "13rem" : "14.9rem"}
          />
        ) : (
          <Image
            src={imgSrc}
            alt={title}
            w={
              !onOtherApps
                ? ["17rem", "17rem", "17rem", "20rem", "23rem"]
                : "auto"
            }
            h="auto"
            minH={isSmallScreen ? "12rem" : "14rem"}
            objectFit="contain"
            borderRadius="xl"
          />
        )}
        <Flex
          p="0.6rem 0.5rem"
          flex={1}
          direction="column"
          justifyContent="space-between"
          color="var(--title-color)"
          h="full"
        >
          <Flex direction="column" textAlign="center">
            <Text fontSize="lg" fontWeight="bold" as="h3">
              {title}
            </Text>
            <Text fontSize="sm">{subtitle}</Text>
          </Flex>
          {rnderCta()}
        </Flex>
      </Flex>
      {showPreview && (
        <ProjectPreview
          {...{
            title,
            subtitle,
            description,
            gif,
            imgSrc,
            onClose,
          }}
          cta={rnderCta(
            {
              w: "full",
              size: "lg",
            },
            { w: "full", size: "lg" }
          )}
        />
      )}
    </>
  );
};
