import { v4 as uuid } from "uuid";
// import photo_1 from "./photo_1.webp";
import photo_2 from "./photo_2.webp";
import photo_3 from "./photo_3.webp";
import photo_4 from "./photo_4.webp";
import photo_5 from "./photo_5.webp";
import photo_6 from "./photo_6.webp";
import photo_7 from "./photo_7.webp";
import photo_8 from "./photo_8.webp";
import photo_9 from "./photo_9.webp";
import photo_10 from "./photo_10.webp";
// import photo_11 from "./photo_11.webp";
import photo_12 from "./photo_12.webp";
import photo_13 from "./photo_13.webp";
import photo_14 from "./photo_14.webp";
import photo_15 from "./photo_15.webp";
// import photo_16 from "./photo_16.webp";
import photo_17 from "./photo_17.webp";
import photo_18 from "./photo_18.webp";
import photo_19 from "./photo_19.webp";
// import photo_20 from "./photo_20.webp";
import photo_21 from "./photo_21.webp";
import photo_22 from "./photo_22.webp";
import photo_23 from "./photo_23.webp";
import photo_24 from "./photo_24.webp";
import photo_25 from "./photo_25.webp";
import photo_26 from "./photo_26.webp";
import photo_27 from "./photo_27.webp";
import photo_28 from "./photo_28.webp";
import photo_29 from "./photo_29.webp";
import photo_30 from "./photo_30.webp";
import photo_31 from "./photo_31.webp";
import photo_32 from "./photo_32.webp";
import photo_33 from "./photo_33.webp";
import photo_34 from "./photo_34.webp";
import photo_35 from "./photo_35.webp";
import photo_36 from "./photo_36.webp";
import photo_37 from "./photo_37.webp";
import photo_38 from "./photo_38.webp";
import photo_39 from "./photo_39.webp";
import photo_40 from "./photo_40.webp";
import photo_41 from "./photo_41.webp";
import photo_42 from "./photo_42.webp";
import photo_43 from "./photo_43.webp";
import photo_44 from "./photo_44.webp";
import photo_45 from "./photo_45.webp";
import photo_46 from "./photo_46.webp";
import photo_47 from "./photo_47.webp";
import photo_48 from "./photo_48.webp";
import photo_49 from "./photo_49.webp";
import photo_50 from "./photo_50.webp";
import photo_51 from "./photo_51.webp";

export const PHOTO_GALLARY = [
  { id: uuid(), src: photo_19 }, //star gazing marine
  { id: uuid(), src: photo_2 },
  { id: uuid(), src: photo_3 },
  { id: uuid(), src: photo_5 },
  { id: uuid(), src: photo_6 },
  { id: uuid(), src: photo_7 },
  { id: uuid(), src: photo_8 },
  { id: uuid(), src: photo_4 },
  { id: uuid(), src: photo_42 }, //amritsar landscape
  { id: uuid(), src: photo_9 },
  { id: uuid(), src: photo_10 },
  // { id: uuid(), src: photo_11 }, limboo
  { id: uuid(), src: photo_12 },
  { id: uuid(), src: photo_13 },
  { id: uuid(), src: photo_15 },
  // { id: uuid(), src: photo_16 }, mirchi
  { id: uuid(), src: photo_17 },
  { id: uuid(), src: photo_18 },
  // { id: uuid(), src: photo_20 }, eye
  { id: uuid(), src: photo_21 },
  { id: uuid(), src: photo_22 },
  { id: uuid(), src: photo_23 },
  { id: uuid(), src: photo_24 },
  { id: uuid(), src: photo_25 },
  { id: uuid(), src: photo_26 },
  { id: uuid(), src: photo_27 },
  { id: uuid(), src: photo_28 },
  { id: uuid(), src: photo_29 },
  { id: uuid(), src: photo_30 },
  { id: uuid(), src: photo_31 },
  { id: uuid(), src: photo_32 },
  { id: uuid(), src: photo_33 },
  { id: uuid(), src: photo_34 },
  { id: uuid(), src: photo_35 },
  { id: uuid(), src: photo_36 },
  { id: uuid(), src: photo_37 },
  { id: uuid(), src: photo_38 },
  { id: uuid(), src: photo_39 },
  { id: uuid(), src: photo_40 },
  { id: uuid(), src: photo_41 },
  { id: uuid(), src: photo_14 }, //star gazing raman science
  { id: uuid(), src: photo_43 },
  { id: uuid(), src: photo_44 },
  { id: uuid(), src: photo_45 },
  { id: uuid(), src: photo_46 },
  { id: uuid(), src: photo_47 },
  { id: uuid(), src: photo_48 },
  { id: uuid(), src: photo_49 },
  { id: uuid(), src: photo_50 },
  { id: uuid(), src: photo_51 },
  // { id: uuid(), src: photo_1 }, //visarjan mobile airoli
];
