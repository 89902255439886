import { Button } from "@chakra-ui/react";
import "./Buttons.css";

export const SecondaryButton = (props) => {
  return (
    <Button
      className="secondary-button"
      fontWeight="normal"
      borderRadius="xl"
      target="_blank"
      variant="solid"
      {...props}
    >
      {props.label}
    </Button>
  );
};
