import {
  Box,
  Button,
  Center,
  Skeleton,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { PhotoImage } from "Components";
import { usePhoto } from "Context/PhotoContext";
import { FaPlus } from "react-icons/fa";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export const PhotosPage = () => {
  const photos = usePhoto();
  const isTouchScreen = useBreakpointValue({ base: true, md: true, lg: false });

  const SKELETON_MIN_HEIGHT = 200;
  const SKELETON_MAX_HEIGHT = isTouchScreen ? 300 : 350;

  return (
    <Box p={[2, 2, 2, 4]} h="full" minH="100vh">
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 375: 2, 768: 3, 1220: 4, 1640: 5 }}
      >
        <Masonry gutter="5px">
          {photos.list?.length > 0 &&
            photos.list.map((photo) => (
              <PhotoImage
                key={photo.id}
                photo={photo}
                isTouchScreen={isTouchScreen}
              />
            ))}
          {photos.isLoading &&
            Array.from({ length: 10 }).map((_, i) => (
              <Skeleton
                key={i}
                w="full"
                h={`${Math.floor(
                  Math.random() *
                    (SKELETON_MAX_HEIGHT - SKELETON_MIN_HEIGHT + 1) +
                    SKELETON_MIN_HEIGHT
                )}px`}
              />
            ))}
        </Masonry>
      </ResponsiveMasonry>
      {photos?.hasMore && !photos.isLoading && (
        <Center w="full" pt={8} mt={8}>
          <Button onClick={photos.loadMore} rightIcon={<FaPlus />}>
            Load More Photos
          </Button>
        </Center>
      )}
      {!photos?.hasMore && !photos.isLoading && (
        <Text fontSize="lg" textAlign="center" mt={14}>
          Wanna see more? 😃 checkout my{" "}
          <Text as="span" fontWeight="bold">
            Instagram and Facebook
          </Text>
        </Text>
      )}
    </Box>
  );
};
