import { Box, Flex, Text } from "@chakra-ui/react";
import { Cards } from "Components";
import { otherProjects } from "Data/otherProjects";
import "./Pages.css";

export const OtherApps = () => {
  return (
    <Box maxW="115rem" mx="auto">
      <Flex className="section-header" direction="column" alignItems="center">
        <Text
          as="h2"
          className="section-title"
          fontSize={["lg", "lg", "2xl"]}
          mt={[24, 24, 24, 24, 28]}
        >
          Other Projects
        </Text>
        <hr />
      </Flex>
      <Flex
        w={["90%", "90%", "95%", "80%"]}
        wrap="wrap"
        justifyContent="center"
        className="project-section"
        gap={["0.5rem", "0.5rem", "1rem", "1rem", "1rem", "2rem"]}
        my={["2rem", "2rem", "2rem", "3rem"]}
        mx="auto"
      >
        {otherProjects.map((project) => (
          <Cards key={project.title} {...project} onOtherApps />
        ))}
      </Flex>
    </Box>
  );
};
