import {
  ae,
  ai,
  chakra,
  figma,
  framer,
  git,
  html,
  js,
  next,
  ps,
  react,
  ts,
  vs,
  xd,
  css,
} from "./ToolsLogo";

export const SKILLS = [
  { title: "ReactJS", src: react },
  { title: "NextJS", src: next },
  { title: "TypeScript", src: ts },
  { title: "JavaScript", src: js },
  { title: "HTML", src: html },
  { title: "CSS", src: css },
  { title: "Git", src: git },
  { title: "Figma", src: figma },
  { title: "XD", src: xd },
  { title: "ChakraUI", src: chakra },
  { title: "Framer", src: framer },
  { title: "Photoshop", src: ps },
  { title: "Illustrator", src: ai },
  { title: "AfterEffect", src: ae },
  { title: "VS Code", src: vs },
];
